import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";
import formTypes from "@/store/shared/form/types";
import snapshotMixinTypes from "@/store/shared/snapshot/types";
import { listingActionTypes, listingGetterTypes, listingMutationTypes } from "@/store/shared/listing/types";
import { pagingActionTypes, pagingMutationTypes } from "@/store/shared/paging/types";
import { sortingActionTypes, sortingMutationTypes } from "@/store/shared/sorting/types";
import { searchMixinTypes } from "@/store/shared/search/types";

export const namespace = "bank-account";

export const getterTypes = {
	...formTypes.getterTypes,
	...snapshotMixinTypes.getterTypes,
	...listingGetterTypes,
	isCardEditingDisabledWithRequests: "isCardEditingDisabledWithRequests"
};

export const actionTypes = {
	...stateManipulationMixinTypes.actionTypes,
	...baseMixinTypes.actionTypes,
	...formTypes.actionTypes,
	...snapshotMixinTypes.actionTypes,
	...pagingActionTypes,
	...sortingActionTypes,
	...listingActionTypes,
	...searchMixinTypes.actionTypes,
	fetchDictionaries: "fetchDictionaries",
	fetchSuggestedProjects: "fetchSuggestedProjects",
	fetchSuggestedBankAndBankBranchByBik: "fetchSuggestedBankAndBankBranchByBik",
	fetchBankAccount: "fetchBankAccount",
	fetchApplicationsForBankAccount: "fetchApplicationsForBankAccount",
	checkBankAccountPayments: "checkBankAccountPayments",
	initializeBankInfo: "initializeBankInfo",
	addEmptyAccountAgreementsItem: "addEmptyAccountAgreementsItem",
	setSelectedBank: "setSelectedBank",
	setSelectedBankBranch: "setSelectedBankBranch",
	setBik: "setBik",
	setLoanApplicationNumber: "setLoanApplicationNumber",
	createBankAccount: "createBankAccount",
	updateBankAccount: "updateBankAccount",
	checkIsBankAccountNumberUnique: "checkIsBankAccountNumberUnique",
	checkIsAgreementNumberUnique: "checkIsAgreementNumberUnique",
	initProjectStatusByFinancingScheme: "initProjectStatusByFinancingScheme"
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	...formTypes.mutationTypes,
	...snapshotMixinTypes.mutationTypes,
	...sortingMutationTypes,
	...pagingMutationTypes,
	...listingMutationTypes,
	...searchMixinTypes.mutationTypes,
	SET_IS_DICTIONARIES_LOADING: "SET_IS_DICTIONARIES_LOADING",
	SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING: "SET_IS_FETCH_BANK_ACCOUNT_INFO_LOADING",
	SET_IS_BANK_ACCOUNT_AGREEMENT_TYPES_HISTORY_LOADING: "SET_IS_BANK_ACCOUNT_AGREEMENT_TYPES_HISTORY_LOADING",
	SET_IS_BANK_ACCOUNT_SAVING: "SET_IS_BANK_ACCOUNT_SAVING",
	SET_IS_BANK_ACCOUNT_FORM_VALID: "SET_IS_BANK_ACCOUNT_FORM_VALID",
	SET_IS_BANK_ACCOUNT_HAS_PAYMENTS: "SET_IS_BANK_ACCOUNT_HAS_PAYMENTS",
	SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE_CHECK_IN_PROGRESS: "SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE_CHECK_IN_PROGRESS",
	SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE: "SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE",
	SET_BANKS: "SET_BANKS",
	RESET_BANK_BRANCHES: "RESET_BANK_BRANCHES",
	SET_BANK_BRANCHES: "SET_BANK_BRANCHES",
	SET_MONITORING_SYSTEM_CONNECTION_TYPES: "SET_MONITORING_SYSTEM_CONNECTION_TYPES",
	SET_SIGN_STATUSES: "SET_SIGN_STATUSES",
	SET_BANK_ACCOUNT_TYPES: "SET_BANK_ACCOUNT_TYPES",
	SET_APPLICATION_STATUSES: "SET_APPLICATION_STATUSES",
	SET_CURRENCIES: "SET_CURRENCIES",
	SET_BANK_RESPONSIBLE_USERS: "SET_BANK_RESPONSIBLE_USERS",
	SET_APPLICATIONS: "SET_APPLICATIONS",
	SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING: "SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING",
	RESET_BIK: "RESET_BIK",
	SET_BIK: "SET_BIK",
	RESET_CORRESPONDENT_ACCOUNT: "RESET_CORRESPONDENT_ACCOUNT",
	SET_CORRESPONDENT_ACCOUNT: "SET_CORRESPONDENT_ACCOUNT",
	SET_BANK_SELECTION_MODE: "SET_BANK_SELECTION_MODE",
	RESET_EDITABLE_ITEM: "RESET_EDITABLE_ITEM",
	SET_EDITABLE_ITEM: "SET_EDITABLE_ITEM",
	RESET_EDITABLE_ITEM_BANK_ID: "RESET_EDITABLE_ITEM_BANK_ID",
	SET_EDITABLE_ITEM_BANK_ID: "SET_EDITABLE_ITEM_BANK_ID",
	RESET_EDITABLE_ITEM_BANK_BRANCH_ID: "RESET_EDITABLE_ITEM_BANK_BRANCH_ID",
	SET_EDITABLE_ITEM_BANK_BRANCH_ID: "SET_EDITABLE_ITEM_BANK_BRANCH_ID",
	SET_EDITABLE_ITEM_ACCOUNT_NUMBER: "SET_EDITABLE_ITEM_ACCOUNT_NUMBER",
	SET_EDITABLE_ITEM_CURRENCY_ID: "SET_EDITABLE_ITEM_CURRENCY_ID",
	SET_EDITABLE_ITEM_OPENED_AT: "SET_EDITABLE_ITEM_OPENED_AT",
	SET_EDITABLE_ITEM_START_ACCEPT_DATE: "SET_EDITABLE_ITEM_START_ACCEPT_DATE",
	SET_EDITABLE_ITEM_END_ACCEPT_DATE: "SET_EDITABLE_ITEM_END_ACCEPT_DATE",
	SET_EDITABLE_ITEM_STATUS: "SET_EDITABLE_ITEM_STATUS",
	SET_EDITABLE_ITEM_MONITORING_SYSTEM_CONNECTION_STATUS: "SET_EDITABLE_ITEM_MONITORING_SYSTEM_CONNECTION_STATUS",
	RESET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID: "RESET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID",
	SET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID: "SET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID",
	SET_EDITABLE_ITEM_CONTROL_DATE: "SET_EDITABLE_ITEM_CONTROL_DATE",
	DELETE_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM: "DELETE_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM",
	ADD_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM: "ADD_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM",
	SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_FETCH_PROJECT_LOADING: "SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_FETCH_PROJECT_LOADING",
	RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS: "RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS",
	SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS: "SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS",
	SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER: "SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER",
	SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE_CHECK_IN_PROGRESS: "SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE_CHECK_IN_PROGRESS",
	SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE: "SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE",
	RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID: "RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID",
	SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID: "SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_OLK_COUNTERPARTY_ID",
	SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_ACCOUNT_TYPE: "SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_ACCOUNT_TYPE"
};

const bankAccountTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default bankAccountTypes;
