import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import ApiSection from "@/api/bod/types/apiSection";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import ChangeRequestsListItem from "@/store/bod/modules/requests/types/changeRequestsListItem";
import RequestsFilter from "@/store/bod/modules/requests/types/requestsFilter";

export default class RequestsState implements IPageState {
	constructor(
		public listing: ListingModel<ChangeRequestsListItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: RequestsFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isSectionsLoading: boolean = false,
		public isUsersLoading: boolean = false,
		public sections: ApiSection[] = [],
		public users: ApiBodUser[] = []
	)
	{
	}
}
