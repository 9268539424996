import ApiBodUser from "@/api/bod/types/apiBodUser";
import IPageState from "@/store/shared/base/types/pageState";

export default class UserState implements IPageState {
	constructor(
		public user: ApiBodUser = new ApiBodUser(),
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isUserLoading: boolean = false
	)
	{
	}
}
