import IPageState from "@/store/shared/base/types/pageState";
import ApiDepartment from "@/api/bod/types/apiDepartment";
import ApiSection from "@/api/bod/types/apiSection";
import ApiFinanceScheme from "@/api/bod/types/apiFinanceScheme";
import FormState from "@/store/shared/form/models/formState";
import ApiBodUser from "@/api/bod/types/apiBodUser";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import ChangeRequestsListItem from "@/store/bod/modules/requests/types/changeRequestsListItem";
import Document from "@/store/bod/modules/document/types/document";
import ApiChangeWarrant from "@/api/bod/types/apiChangeWarrant";
import ChangeRequest from "@/store/bod/modules/document/types/changeRequest";
import { DocumentPermissions } from "@/store/bod/modules/document/types/documentPermissions";
import { RequestPermissions } from "@/store/bod/modules/document/types/requestPermissions";
import ApiBodFile from "@/api/bod/types/apiBodFile";

export default class DocumentState implements IPageState {
	constructor(
		public listing: ListingModel<ChangeRequestsListItem>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isUsersLoading: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public isSectionsLoading: boolean = false,
		public isFinanceSchemesLoading: boolean = false,
		public isFinanceSchemesLoaded: boolean = false,
		public isDocumentFormSaving: boolean = false,
		public isRequestFormSaving: boolean = false,
		public isAccepting: boolean = false,
		public isRejecting: boolean = false,
		public isAcceptButtonsHidden: boolean = false,
		public departments: ApiDepartment[] = [],
		public sections: ApiSection[] = [],
		public financeSchemes: ApiFinanceScheme[] = [],
		public users: ApiBodUser[] = [],
		public document: Document = new Document(),
		public editableFile: FileMeta = new FileMeta(),
		public nonEditableFile: FileMeta = new FileMeta(),
		public requestEditableFile: FileMeta = new FileMeta(),
		public requestNonEditableFile: FileMeta = new FileMeta(),
		public changeWarrantFile: ApiBodFile = new ApiBodFile(),
		public changeWarrantFileMeta: FileMeta = new FileMeta(),
		public documentChangeWarrantFileMeta: FileMeta = new FileMeta(),
		public history: ChangeRequestsListItem[] = [],
		public changeWarrants: ApiChangeWarrant[] = [],
		public request: ChangeRequest = new ChangeRequest(),
		public documentPermissions: DocumentPermissions[] = [],
		public requestPermissions: RequestPermissions[] = [],
		public declineReason: string = "",
		public savedDocumentIsWeb: boolean = false
	)
	{
	}
}
